import { DialogService }                    from 'aurelia-dialog';
import { inject }                           from 'aurelia-framework';
import { ExternalArtworkListingModal }      from 'modules/core/external-tables/artworks/listings-modal';
import { ExternalJunctionListingModal }     from 'modules/core/external-tables/junctions/listings-modal';
import { AdditionalInformationsRepository } from 'modules/management/additional-information/additional-informations/services/repository';
import { ConstructionsRepository }          from 'modules/management/concessions/constructions/services/repository';
import { ConstructionType }                 from 'modules/management/models/construction-type';
import { LotConstruction }                  from 'modules/management/models/lot-construction';

@inject(ConstructionsRepository, DialogService, AdditionalInformationsRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param constructionsRepository
     * @param dialogService
     * @param additionalInformationsRepository
     */
    constructor(constructionsRepository, dialogService, additionalInformationsRepository) {
        this.constructionsRepository          = constructionsRepository;
        this.dialogService                    = dialogService;
        this.additionalInformationsRepository = additionalInformationsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {LotConstruction}
     */
    model() {
        let model = new LotConstruction();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns info form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    infoSchema(viewModel, readonly = false) {
        this.concession = {
            type:       'text',
            key:        'concession',
            label:      'form.field.concession',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.highway = {
            type:       'text',
            key:        'highway',
            label:      'form.field.highway',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        const schema = [
            [this.concession, this.highway],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            return [
                ...schema,
                [this.created_by, this.created_at],
            ];
        }

        return schema;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.element_id = {
            type:         'select2',
            key:          'element_id',
            label:        viewModel.fieldTitle,
            size:         6,
            remoteSource: () => this.constructionsRepository.allByType(viewModel.construction_type_id),
            attributes:   {
                disabled: readonly,
            },
        };

        this.designation = {
            type:       'text',
            key:        'designation',
            label:      'form.field.designation',
            size:       6,
            required:   false,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.number = {
            type:       'text',
            key:        'number',
            label:      'form.field.number',
            size:       6,
            required:   false,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.code',
            size:       6,
            required:   false,
            attributes: {
                maxlength: 10,
                readonly:  readonly,
            },
            inputGroup: {
                position: 'right',
                visible:  !readonly,
                button:   {
                    visible: Number(viewModel.construction_type_id) !== ConstructionType.OH_TYPES,
                    action:  () => this.openSelectionModal(viewModel),
                    icon:    {
                        class: 'icon-search4',
                    },
                },
            },
        };

        this.pk = {
            type:     'field-group',
            label:    'form.field.pk-location',
            key:      'pk',
            required: false,
            size:     2,
            fields:   [
                {
                    type:            'text',
                    key:             'pk_s',
                    showLabel:       false,
                    required:        false,
                    size:            2,
                    observers:       [
                        (newValue) => newValue.length === 3 && this.focusField('pk_e'),
                    ],
                    valueConverters: [
                        { name: 'numbersOnly' },
                    ],
                    attributes:      {
                        maxlength: 3,
                        readonly:  readonly,
                    },
                },
                {
                    type:  'divider',
                    label: '+',
                    size:  '3p',
                },
                {
                    type:            'text',
                    key:             'pk_e',
                    showLabel:       false,
                    required:        false,
                    size:            3,
                    valueConverters: [
                        { name: 'autoDecimalValue' },
                    ],
                    attributes:      {
                        maxlength: 6,
                        readonly:  readonly,
                    },
                },
            ],
        };

        return [
            [this.element_id, this.designation],
            [this.number, this.code],
            [this.pk],
        ];
    }

    /**
     * Returns form schema for the design tab
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    designSchema(viewModel, readonly = false) {
        this.design_ids = {
            type:         'duallistbox',
            key:          'design_ids',
            label:        'form.field.drawings',
            required:     false,
            size:         12,
            remoteSource: () => this.additionalInformationsRepository.allDesignsFromLotIntervention(viewModel.parent_id),
            attributes:   {
                disabled: readonly,
            },
        };

        return [
            [this.design_ids],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('management.concessions.concessions.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Returns the modal to be used
     *
     * @param typeId
     * @return {*}
     */
    getModal(typeId) {
        const modals = {
            [ConstructionType.OE_ELEMENTS]: ExternalJunctionListingModal,
            [ConstructionType.OA_TYPES]:    ExternalArtworkListingModal,
        };

        return modals[typeId];
    }

    /**
     * Opens the highway selection modal
     */
    openSelectionModal(viewModel) {
        const modal = this.getModal(viewModel.construction_type_id);

        this.dialogService.open({
            viewModel: modal,
            model:     {},
        }).whenClosed((response) => {
            if (!response.wasCancelled) {
                viewModel.formSchema.code.instance.model.value = response.output.code;
            }
        });
    }

    /**
     * Focus on a different field
     * @param fieldKey
     */
    focusField(fieldKey) {
        document.querySelector(`#${fieldKey}`)?.focus();
    }

}
