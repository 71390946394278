import { DialogService }     from 'aurelia-dialog';
import { bindable, inject }  from 'aurelia-framework';
import { I18N }              from 'aurelia-i18n';
import { BaseListViewModel } from 'base-list-view-model';
import { CreateOEDataModal } from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/create';
import { EditOEDataModal }   from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/edit';
import { OEDatasRepository } from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/services/repository';
import { ViewOEDataModal }   from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/view';
import { AppContainer }      from 'resources/services/app-container';
import { Downloader }        from 'resources/services/downloader';

@inject(AppContainer, OEDatasRepository, I18N, Downloader, DialogService)
export class ListOEDatas extends BaseListViewModel {

    listingId = 'management-specifications-listings-revisions-listing';

    @bindable headerTitle    = null;
    @bindable newRecordRoute = 'management.concessions.concessions.lot-constructions.oe-data.create';
    @bindable repository;
    @bindable datatable;
    @bindable listing;

    readonly = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param dialogService
     */
    constructor(appContainer, repository, i18N, downloader, dialogService) {
        super(appContainer);

        this.i18N          = i18N;
        this.repository    = repository;
        this.downloader    = downloader;
        this.dialogService = dialogService;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.concessions.concessions.manage',
            'management.concessions.concessions.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.lot_construction = params.model;
        this.readonly         = params.readonly;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         {
                search:          (criteria) => this.search(criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            show:               {
                action:  (row) => this.openModal(row, ViewOEDataModal),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.view']),
            },
            edit:               {
                action:  (row) => this.openModal(row, EditOEDataModal),
                visible: (row) => !this.readonly && this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.edit']),
            },
            destroy:            !this.readonly && this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.delete']),
            destroySelected:    !this.readonly,
            actionsContextMenu: true,
            actions:            [],
            options:            [],
            buttons:            [
                {
                    label:            'form.button.create-new',
                    icon:             'icon-plus3',
                    visible:          !this.readonly && this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.create']),
                    action:           () => this.openModal(this, CreateOEDataModal),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:         true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:  'pk',
                    name:  'o_e_lot_construction_data.pks_s',
                    title: this.i18N.tr('form.field.pk-start') + ' - ' + this.i18N.tr('form.field.pk-finish'),
                },
                {
                    data:   'pk',
                    name:   'o_e_lot_construction_data.pks_f',
                    title:  this.i18N.tr('form.field.pk-start'),
                    hidden: true,
                },
                {
                    data:   'pk',
                    name:   'o_e_lot_construction_data.pkf_s',
                    title:  this.i18N.tr('form.field.pk-start'),
                    hidden: true,
                },
                {
                    data:   'pk',
                    name:   'o_e_lot_construction_data.pkf_f',
                    title:  this.i18N.tr('form.field.pk-finish'),
                    hidden: true,
                },
                {
                    data:  'pk_type',
                    name:  'p_k_type_translations.name',
                    title: 'form.field.pk-type',
                },
                {
                    data:  'number_of_lanes',
                    name:  'o_e_lot_construction_data.number_of_lanes',
                    title: 'form.field.number-of-lanes',
                },
                {
                    data:  'axis',
                    name:  'axis_translations.name',
                    title: 'form.field.axis',
                },
                {
                    data:  'branch',
                    name:  'branch_translations.name',
                    title: 'form.field.branch',
                },
                {
                    data:  'edge',
                    name:  'edge_translations.name',
                    title: 'form.field.edge',
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.repository.search({ ...criteria, lot_construction_id: this.lot_construction.id });
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }
}
