import { inject }                     from 'aurelia-framework';
import { PLATFORM }                   from 'aurelia-pal';
import { BaseFormViewModel }          from 'base-form-view-model';
import { FormSchema }                 from 'modules/management/concessions/concessions-tree/lot-constructions/form-schema';
import { LotConstructionsRepository } from 'modules/management/concessions/concessions-tree/lot-constructions/services/repository';
import { LotInterventionsRepository } from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { LotConstructionType }        from 'modules/management/models/lot-construction-type';
import { AppContainer }               from 'resources/services/app-container';

@inject(AppContainer, LotConstructionsRepository, FormSchema, LotInterventionsRepository)
export class CreateLotConstruction extends BaseFormViewModel {

    headerTitle     = 'form.title.create-new-record';
    elementTabLabel = 'form.title.general-data';
    type;
    construction_type_id;
    lot_construction_type_id;
    lot_construction_type_slug;
    parent_id;
    fieldTitle;
    formId;
    isOE;

    alert  = {};
    model  = {};
    schema = {};

    create = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param lotInterventionsRepository
     */
    constructor(appContainer, repository, formSchema, lotInterventionsRepository) {
        super(appContainer);

        this.repository                 = repository;
        this.formSchema                 = formSchema;
        this.lotInterventionsRepository = lotInterventionsRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-constructions/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate(params) {
        return super.can([
            'management.concessions.concessions.manage',
            'management.concessions.concessions.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.parent_id                  = params.filterModel.parent_id;
        this.construction_type_id       = params.type.id;
        this.lot_construction_type_slug = params.type.slug;
        this.fieldTitle                 = params.type.title;
        this.lot_construction_type_id   = params.type.lot_construction_type_id;
        this.isOE                       = Number(params.type.lot_construction_type_id) === LotConstructionType.OE;

        if (this.isOE) {
            this.elementTabLabel = 'form.title.element';
        }

        this.formId = `management.concessions.lot-constructions-${this.lot_construction_type_slug}.create-form`;

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchInfoData().then(() => {
            this.schema       = this.formSchema.schema(this);
            this.designSchema = this.formSchema.designSchema(this);
            this.infoSchema   = this.formSchema.infoSchema(this);
            this.globalSchema = this.formSchema.globalSchema(this);
        });
    }

    /**
     * Fetches data from server
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchInfoData() {
        const response = await this.lotInterventionsRepository.find(this.parent_id);
        this.initialModel.assign({ ...this.initialModel, highway: response.highway_name, concession: response.concession_name });
        this.model.assign({ ...this.model, highway: response.highway_name, concession: response.concession_name });
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model, this.parent_id, this.lot_construction_type_id)
            .then((response) => {
                this.handleResponse(response, 'management.concessions.concessions.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

}
