import { EventAggregator }         from 'aurelia-event-aggregator';
import { inject }                  from 'aurelia-framework';
import { I18N }                    from 'aurelia-i18n';
import { ConstructionsRepository } from 'modules/management/concessions/constructions/services/repository';
import { EntitiesRepository }      from 'modules/management/concessions/entities/services/repository';
import { LotConstructionType }     from 'modules/management/models/lot-construction-type';
import { BaseFilterFormSchema }    from 'resources/classes/base-filter-form-schema';
import { SessionStorage }          from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, ConstructionsRepository, EntitiesRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        parent_id:        null,
        parent_key:       null,
        construction_ids: null,
        number:           null,
        designation:      null,
        code:             null,
        entity_ids:       null,
        created_by:       [],
        created_at_from:  null,
        created_at_to:    null,
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param constructionsRepository
     * @param entitiesRepository
     */
    constructor(storage, eventAggregator, i18n, constructionsRepository, entitiesRepository) {
        super(storage, eventAggregator, i18n);

        this.constructionsRepository = constructionsRepository;
        this.entitiesRepository      = entitiesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel, parentId, parentKey) {
        this.modelDefaults.parent_id  = parentId;
        this.modelDefaults.parent_key = parentKey;

        this.modelDefaults.construction_id = null;
        this.modelDefaults.number          = null;
        this.modelDefaults.designation     = null;
        this.modelDefaults.code            = null;
        this.modelDefaults.entity_id       = null;

        return super.filterModel(viewModel, this.modelDefaults, viewModel.listingId, false);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        const isOE = viewModel.lotConstructionTypeId === LotConstructionType.OE;

        this.construction_ids = {
            type:         'multiselect-native',
            key:          'construction_ids',
            label:        viewModel.getLotConstructionTypeInfo('title'),
            size:         6,
            required:     false,
            remoteSource: () => this.constructionsRepository.allByType(viewModel.getLotConstructionTypeInfo('constructionTypeId')),
        };

        this.number = {
            type:     'text',
            key:      'number',
            label:    'form.field.number',
            size:     6,
            required: false,
        };

        this.designation = {
            type:     'text',
            key:      'designation',
            label:    'form.field.designation',
            size:     6,
            required: false,
        };

        this.code = {
            type:     'text',
            key:      'code',
            label:    'form.field.code',
            size:     6,
            hidden:   !isOE,
            required: false,
        };

        this.entity_ids = {
            type:         'multiselect-native',
            key:          'entity_ids',
            label:        'form.field.entity',
            size:         6,
            required:     false,
            hidden:       isOE,
            remoteSource: () => this.entitiesRepository.all(),
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at(from)',
            size:     6,
            required: false,
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at(to)',
            size:     6,
            required: false,
        };

        this.created_by = {
            type:     'users-multiselect',
            key:      'created_by',
            label:    'form.field.created-by',
            size:     6,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', {
                listingId:      viewModel.listingId,
                ignoredFilters: ['parent_id', 'parent_key'],
            }),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return isOE ? [
            [this.designation, this.number],
            [this.construction_ids, this.code],
            [this.created_at_from, this.created_at_to],
            [this.created_by],
            [this.buttons],
        ] : [
            [this.construction_ids, this.number],
            [this.designation, this.entity_ids],
            [this.created_at_from, this.created_at_to],
            [this.created_by],
            [this.buttons],
        ];
    }
}
