import { inject }                     from 'aurelia-framework';
import { PLATFORM }                   from 'aurelia-pal';
import { BaseFormViewModel }          from 'base-form-view-model';
import { FormSchema }                 from 'modules/management/concessions/concessions-tree/lot-constructions/form-schema';
import { LotConstructionsRepository } from 'modules/management/concessions/concessions-tree/lot-constructions/services/repository';
import { LotConstructionType }        from 'modules/management/models/lot-construction-type';
import { AppContainer }               from 'resources/services/app-container';

@inject(AppContainer, LotConstructionsRepository, FormSchema)
export class EditLotConstruction extends BaseFormViewModel {

    headerTitle     = 'form.title.edit-record';
    elementTabLabel = 'form.title.general-data';
    formId;
    type;
    construction_type_id;
    lot_construction_type_slug;
    parent_id;
    fieldTitle;

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate(params) {
        return super.can([
            'management.concessions.concessions.manage',
            'management.concessions.concessions.edit',
        ]);
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-constructions/form.html');
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.parent_id                  = params.filterModel.parent_id;
        this.construction_type_id       = params.type.id;
        this.lot_construction_type_slug = params.type.slug;
        this.lot_construction_type_id   = params.type.lot_construction_type_id;
        this.fieldTitle                 = params.type.title;
        this.isOE                       = Number(params.type.lot_construction_type_id) === LotConstructionType.OE;

        if (this.isOE) {
            this.elementTabLabel = 'form.title.element';
        }

        this.formId = `management.concessions.lot-constructions-${this.lot_construction_type_slug}.edit-form`;

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema       = this.formSchema.schema(this);
            this.designSchema = this.formSchema.designSchema(this);
            this.infoSchema   = this.formSchema.infoSchema(this);
            this.globalSchema = this.formSchema.globalSchema(this);
        });
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    submit() {
        this.alert = null;

        this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response, 'management.concessions.concessions.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
